import * as React from "react";

import { UserContext } from "../user/UserContext";
import { Spinner } from "../system/atoms/Spinner";
import { Button } from "../user/Button";

interface GoogleSingleSignOnProps {
  auto_select: boolean;
  useOneTap: boolean;
}

export default function GoogleSingleSignOn(props: GoogleSingleSignOnProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isFinished, setIsFinished] = React.useState<boolean>(false);

  return (
    <div className="w-full">
      <UserContext.Consumer>
        {(userContext) => (
          <LoginComponent
            userContext={userContext}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isFinished={isFinished}
            setIsFinished={setIsFinished}
          ></LoginComponent>
        )}
      </UserContext.Consumer>
    </div>
  );
}

function LoginComponent({
  userContext,
  isLoading,
  setIsLoading,
  isFinished,
  setIsFinished,
}: {
  userContext: any;
  isLoading: boolean;
  setIsLoading: any;
  isFinished: boolean;
  setIsFinished: any;
}) {
  const login = () => {
    window.open(
      (process.env.REACT_APP_API_URL || "") + "/v1/auth/login",
      "_self",
    );
  };
  if (isLoading) {
    return <Spinner enabled={true} />;
  }
  if (isFinished) {
    return <div>Login successful.</div>;
  }
  return (
    <div className="login-container">
      <button
        type="button"
        className={
          "items-center rounded border my-4 px-6 py-4 text-xl font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2 w-full " +
          "bg-[#FF6C71] text-white hover:bg-rose-500 border-transparent rounded-lg"
        }
        onClick={() => login()}
      >
        Sign in
      </button>
      <div className="text-center text-[12px] text-gray-300 mt-4">
        By signing in, you agree to our{" "}
        <a href="/privacy-policy" className="underline text-teal-600">
          Privacy Policy
        </a>{" "}
        and{" "}
        <a href="/terms-of-service" className="underline text-teal-600">
          Terms of Service
        </a>.
      </div>
    </div>

  );
}

GoogleSingleSignOn.defaultProps = {
  auto_select: true,
  useOneTap: true,
};
