import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";

import { initializeStatsig } from "./utils/statsig";

import { Playground } from "./components/playground/Playground";
import Login from "./components/login/Login";
import NavigationBar from "./components/navigation-bar/NavigationBar";
import StripeSuccess from "./components/payments/StripeSuccess";
import TrainPage from "./components/train/TrainPage";
import SharePage from "./components/SharePage";
import { UserContext, UserContextType } from "./components/user/UserContext";
import UserInfoPage, { UserInfo } from "./components/user/UserInfoPage";
import UsagePage from "./components/user/UsagePage";
import ShareTrainJobPage from "./components/train/ShareTrainJobPage";
import AdminPage from "./components/admin/AdminPage";
import Statsig from "statsig-js";
import { useContext } from "react";
import { GlobalDataContext, GlobalDataContextType } from "./GlobalDataContext";

const defaultProfile = {
  token: null,
};

const RedirectWithParam = () => {
  const { trainJobID } = useParams(); // Get the trainJobID from the current route
  return <Navigate to={`/tune/${trainJobID}`} replace />;
};

function AppProvider({
  context,
  token,
}: {
  context: UserContextType;
  token: string;
}) {
  const globalData: GlobalDataContextType =
    useContext<GlobalDataContextType>(GlobalDataContext);
  const [userInfo, setUserInfo] = React.useState<UserInfo | null>(null);
  React.useEffect(() => {
    if (globalData.loading) {
      return;
    }
    getUserInfoFromBackend(token, globalData)
      .then(setUserInfo)
      .catch(console.error);
  }, [globalData]);

  if (globalData.loading) {
    return <div>Loading...</div>;
  }

  if (userInfo === null) {
    return null;
  }

  return (
    <Router>
      <UserContext.Provider value={context}>
        <NavigationBar userInfo={userInfo} />
      </UserContext.Provider>
      <Routes>
        <Route
          path="/account"
          element={
            <UserInfoPage
              token={token}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
            />
          }
        />
        {Statsig.checkGate("usage_tab") && (
          <Route path="/usage" element={<UsagePage token={token} />} />
        )}
        {Statsig.checkGate("admin_console") && (
          <Route path="/admin" element={<AdminPage token={token} />} />
        )}
        <Route
          path="/documentation"
          Component={() => {
            window.location.replace("https://lamini-ai.github.io");
            return null;
          }}
        />
        <Route
          path="/playground"
          element={<Playground token={token} userInfo={userInfo} />}
        />
        <Route path="/stripe/success" element={<StripeSuccess />} />
        <Route path="/train" element={<Navigate to="/tune" replace />} />
        <Route
          path="/tune"
          element={<TrainPage token={token} userInfo={userInfo} />}
        />
        <Route path="/train/:trainJobID" element={<RedirectWithParam />} />
        <Route
          path="/tune/:trainJobID"
          element={<ShareTrainJobPage token={token} userInfo={userInfo} />}
        />
        <Route
          path="/share"
          element={<SharePage token={token} userInfo={userInfo} />} // TODO: Deprecate this
        />
        <Route path="*" element={<Navigate to="/tune" replace />} />
      </Routes>
    </Router>
  );
}

export default function App() {
  const [profile, setProfile] = React.useState(defaultProfile);
  const [isQueryingForToken, setIsQueryingForToken] = React.useState(true);
  const context = { profile: profile, setProfile: setProfile };
  React.useEffect(() => {
    if (!profile.token) {
      getConfigTokenFromBackend()
        .then((data) => {
          setProfile(data);
          setIsQueryingForToken(false);
        })
        .catch(console.log);
    }
  }, []);

  if (!profile.token) {
    return (
      <UserContext.Provider value={context}>
        <Login isQueryingForToken={isQueryingForToken} />
      </UserContext.Provider>
    );
  }

  return <AppProvider context={context} token={profile.token} />;
}

async function getUserInfoFromBackend(
  token: string,
  globalData: GlobalDataContextType
) {
  const res = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/user/info",
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  const data = await res.json();
  try {
    await initializeStatsig(
      data.email,
      globalData.config.statsig_client_public_key,
      globalData.config.environment,
      globalData.config.statsig_local_mode
    );
  } catch (e) {}
  return data;
}

async function getConfigTokenFromBackend() {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 3000);
  let data = {
    is_granted: false,
    token: null,
    credentials: null,
  };
  try {
    const res = await fetch(
      (process.env.REACT_APP_API_URL || "") + "/v1/auth/config_token",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        signal: controller.signal,
      }
    );
    data = await res.json();
  } catch (e) {}
  clearTimeout(timeoutId); // Clear the timeout if the request is successful
  return data;
}
