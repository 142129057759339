import * as React from "react";

import logo from "../logos/logo-llama-only-white.png";
import GoogleSingleSignOn from "./GoogleSingleSignOn";

import "./Login.css";
import { Spinner } from "../system/atoms/Spinner";

interface LoginProps {
  isQueryingForToken: boolean;
}

export default function Login({ isQueryingForToken }: LoginProps) {
  return (
    <div className="h-screen bg-[#212121]">
      <div className="flex items-center h-full">
        <div className="m-auto bg-[#313131]">
          <div className="p-8 text-[#f1f1f1] border-[#5f5f5f] border rounded-md w-80 h-100">
            <img src={logo} className="w-20 m-auto" />

            <h3 className="text-center mb-1 mt-2 text-lg font-bold">
              Welcome!
            </h3>
            {isQueryingForToken ? (
              <Spinner enabled={true}></Spinner>
            ) : (
              <div className="login-button m-0">
                <GoogleSingleSignOn useOneTap={true} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
