import * as Sentry from "@sentry/react";

export async function fetchStreamingCustom(
  finalQuestion: string,
  baseModel: string | null,
  setModelResponse: (response: string, responseType: string) => void,
  token: string,
  outputLength: number,
  giveUpAfter: number,
  showWarningTime: number | undefined,
  setIsCreditModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const body = {
    id: "website",
    model_name: baseModel,
    prompt: finalQuestion,
    max_new_tokens: outputLength,
  };

  // Define a polling interval in milliseconds
  const pollingInterval = 60; // polling interval in milliseconds
  const maxDurationInSeconds = 150; // maximum duration in seconds
  // Use a flag to control the polling loop
  let stopPolling = false;
  let hasReceivedFirstToken = false;
  let elapsedTime = 0;
  const startTime = Date.now();
  let currentResponseLength = 0;
  let server = null;
  while (!stopPolling) {
    // let startTimeI = Date.now();
    if (elapsedTime > giveUpAfter * 1000 && !hasReceivedFirstToken) {
      setModelResponse(
        "There is a large amount of traffic currently, waiting for a response.",
        "warning"
      );
      Sentry.captureException(
        new Error("Oops, there is too much traffic. Please try again.")
      );
      return;
    } else if (
      showWarningTime != null &&
      elapsedTime > showWarningTime * 1000 &&
      !hasReceivedFirstToken
    ) {
      setModelResponse(
        "There is a large amount of traffic currently, waiting for a response...",
        "warning"
      );
    }
    if (elapsedTime > maxDurationInSeconds * 1000) {
      return;
    }
    ({ stopPolling, hasReceivedFirstToken, currentResponseLength, server } =
      await fetchStreamingCustomOne(
        body,
        myHeaders,
        setModelResponse,
        hasReceivedFirstToken,
        showWarningTime == null
          ? giveUpAfter
          : Math.min(giveUpAfter, showWarningTime),
        currentResponseLength,
        server,
        setIsCreditModalOpen
      ));
    // Wait for the next polling interval
    await new Promise((resolve) => setTimeout(resolve, pollingInterval));

    elapsedTime = Date.now() - startTime;
  }
}

export async function fetchStreamingCustomOne(
  body: any,
  myHeaders: any,
  setModelResponse: (response: string, responseType: string) => void,
  hasReceivedFirstToken: boolean,
  request_timeout: number, // maximum duration for wait to get first token in seconds
  currentResponseLength: number,
  server: number | null,
  setIsCreditModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) {
  const controller = new AbortController();
  const timeoutId = setTimeout(
    () => controller.abort(),
    request_timeout * 1000
  ); // 3000 milliseconds timeout

  try {
    body.server = server;
    const response = await fetch(
      (process.env.REACT_APP_API_URL || "") + "/v1/streaming_completions",
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: myHeaders,
        signal: controller.signal, // Pass the abort signal to the fetch options
      }
    );
    const responseJson = await response.json();
    clearTimeout(timeoutId); // Clear the timeout if the request is successful

    Sentry.setTag("model_name", body.model_name);
    Sentry.setTag("status", response.status);

    let error_message = "";
    if (!response.ok) {
      if (response.status === 513) {
        error_message =
          "The requested model is currently being downloaded to our servers. This process typically takes a few minutes. Please try again shortly. If the issue persists after 15 minutes, contact our support team.";
      } else if (response.status === 503) {
        error_message =
          "Our servers are temporarily unavailable. We're working to resolve this as quickly as possible. Please try again in a few minutes.";
      } else if (response.status === 561) {
        error_message =
          "You don't have access to this model. This may be due to account restrictions. Please try another model.";
      } else if (response.status === 402) {
        error_message =
          "Your account has insufficient credits. To continue using Lamini, please buy more credits.";
        setIsCreditModalOpen(true);
      } else {
        error_message =
          "An unexpected error occurred. We've logged the issue and our team is investigating. Please try your request again. If the problem persists, contact our support team with error ID.";
      }

      Sentry.addBreadcrumb({
        category: "request_body",
        message:
          "model_name: " +
          body.model_name +
          "\nprompt: " +
          body.prompt +
          "\noutput_length: " +
          body.max_tokens, // This isn't max_token because Sentry thinks it's sensitive if there's the word token
        level: "info",
      });
      setModelResponse(error_message, "error");
      Sentry.captureException(new Error(error_message));
      return {
        stopPolling: true,
        hasReceivedFirstToken,
        currentResponseLength,
        server,
      };
    }

    // Initialize an empty response
    let partialResponse = "";
    let stopPolling = responseJson.status[0];
    // Append the received chunk to the partial response
    partialResponse += responseJson.data[0].output;
    // Update the model response in the state
    if (partialResponse === "") {
      // Don't set the ModelResponse else it would remove the loading dots
      return {
        stopPolling: false,
        hasReceivedFirstToken,
        currentResponseLength,
        server: responseJson.server,
      };
    }
    if (partialResponse.length < currentResponseLength) {
      return {
        stopPolling,
        hasReceivedFirstToken: true,
        currentResponseLength,
        server: responseJson.server,
      };
    }
    setModelResponse(partialResponse, "model");

    if (stopPolling) {
      // Final response
      Sentry.addBreadcrumb({
        category: "request_body",
        message:
          "model_name: " +
          body.model_name +
          "\nprompt: " +
          body.prompt +
          "\noutput_length: " +
          body.max_tokens, // This isn't max_token because Sentry thinks it's sensitive if there's the word token
        level: "info",
      });
      Sentry.captureEvent({
        message: "Successful completions API call",
        level: "info",
      });
    }

    return {
      stopPolling,
      hasReceivedFirstToken: true,
      currentResponseLength: partialResponse.length,
      server: responseJson.server,
    };
  } catch (error) {
    // Handle abort error
    if (error instanceof Error && error.name === "AbortError") {
      return {
        stopPolling: false,
        hasReceivedFirstToken,
        currentResponseLength,
        server,
      };
    } else if (
      error instanceof TypeError &&
      error.message === "Failed to fetch"
    ) {
      // Handle network errors
      setModelResponse(
        "A network error occurred while processing your request. Please check your internet connection and try again. If the issue continues, it may be a problem on our end.",
        "error"
      );
      Sentry.captureException(
        new Error(
          "Oops, a network error occurred. Please try again in a few minutes."
        )
      );
    } else {
      // Handle other errors
      setModelResponse(
        "Oops, something went wrong. Please try again.",
        "error"
      );
      Sentry.captureException(
        new Error("Oops, something went wrong. Please try again.")
      );
    }
    return {
      stopPolling: true,
      hasReceivedFirstToken,
      currentResponseLength,
      server,
    };
  }
}
